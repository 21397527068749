@import "./scss/fonts.css";

@import "./scss/normalize.css";

body {
  font-size: 16px;
  &.lock-backdrop {
    width: 100%;
    position: fixed;
    -ms-touch-action: none;
    touch-action: none;
    overscroll-behavior: none;
  }

  &.lock-new {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }
}

main {
  flex-grow: 1;
}

body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
